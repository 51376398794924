import ApplicationController from 'modules/application_controller';
import SlidesLiveEmbed from 'modules/slideslive_embed/slideslive_embed';
import slidesLiveDomain from 'plugins/utilities/slideslive_domain';

export default class extends ApplicationController {
  static get values() {
    return {
      presentationId: {
        type: String,
        default: undefined,
      },
    };
  }

  initialize() {
    this.embed = null;
  }

  connect() {
    if (!this.presentationIdValue) return;
    if (this.isTurboPreview) return;

    this.embed = new SlidesLiveEmbed('homepage-player', {
      presentationId: this.presentationIdValue,
      autoPlay: false,
      verticalEnabled: true,
      host: `https://${slidesLiveDomain()}`,
      locale: gon.locale,
    });
  }

  disconnect() {
    if (this.embed) {
      this.embed.destroy();
      this.embed = null;
    }
  }
}
